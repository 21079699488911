import React, { useState, useEffect } from 'react';
import * as qf from '../misc/qf';

const FollowTextWithIcon = ({ obj }) => {
  const [following, setFollowing] = useState(false);
  const follow = obj._links.follow.href;
  const unfollow = obj._links.unfollow.href;

  useEffect( () => {
    qf.getJSON(follow)
      .then( res => setFollowing(res.following))
      .catch( err => console.error(err) );
  }, [obj])

  const toggleFollow = () => {
    const url = following ? unfollow : follow;
    qf.postJSON(url)
      .then( res => setFollowing(res.following))
      .catch(err => console.error(err));
  }

  return (
    <a onClick={toggleFollow}>
      {
        following ? (
          <>
            <i className="fa-solid fa-user-minus me-1"></i>
            Unfollow
          </>
        ) : (
          <>
            <i className="fa-solid fa-user-plus me-1"></i>
            Follow
          </>
        )
      }
    </a>
  )
}

export default FollowTextWithIcon;
