import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded.js';
import FollowTextWithIcon from '../../src/follow/FollowTextWithIcon.jsx';

const el = document.querySelectorAll('.FollowText');
let obj = {}
if (preloaded.school) obj = preloaded.school;
if (preloaded.user_follow_url) obj = preloaded.user_follow_url;

let preVals = {}
if (preloaded.school) preVals = preloaded.school;
if (preloaded.club) preVals = preloaded.club;
if (preloaded.band) preVals = preloaded.band;


if (el) {
  Array.from(el).forEach( el => {
    ReactDOM.render(
      <FollowTextWithIcon
        obj={preVals}
      />
    , el)
  });
};
